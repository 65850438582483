import "./src/styles/bs-carousel.css";
import "./src/styles/styles.css";
import "./src/styles/flicking.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import { Amplify, Auth } from "aws-amplify";
import "@stripe/stripe-js";
import awsmobile from "./src/aws-exports";
Amplify.configure(awsmobile);
Auth.configure(awsmobile);

require("typeface-inter");
require("@tailwindcss/custom-forms");
